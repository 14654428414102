.footer-container {
  display: flex;
  flex-direction: column;

  padding: 20px 15%;

  background: black;

  .contact-us-text {
    padding: 3px 0;
    color: #d9dee4;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .address {
    color: #666;
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.list-item-container {
  display: flex;
  color: #666;
  margin: 5px 0;
}
