.description,
.side-content {
  color: black;
  font-weight: 500;
  font-size: 20px;
}

.container {
  .team {
    .row {
      display: flex;
      justify-content: center;

      .col-md-4 {
        .team-player {
          img {
            max-width: 250px;
            width: 250px;
            height: 250px;
          }
        }
      }
    }
  }
}

.blockquote {
  font-weight: 400;
}
